(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

var _ = require('lodash');
var ui = require('./ui.js');

var scroll = require('./scrollTo.js');

var _require = require('lodash'),
    unset = _require.unset;

module.exports = {

	init: function init() {
		var _this = this;

		this.isiInlineOrSticky();

		var debouncedInlineOrSticky = _.throttle(function () {
			_this.isiInlineOrSticky();
		}, 50);

		$(window).scroll(function () {
			debouncedInlineOrSticky();
		});

		$(document).on('click', '.trigger.closed', function (e) {
			$(e.currentTarget).removeClass('closed').addClass('opened');
			$('.collapsed-isi').addClass('tall');
		});

		$(document).on('click', '.trigger.opened', function () {
			$('.trigger.opened').removeClass('opened').addClass('closed');
			$('.collapsed-isi .isi-indications-wrapper').scrollTop(0);
			$('.collapsed-isi').removeClass('tall');
		});
	},

	isiInlineOrSticky: function isiInlineOrSticky() {
		if (this.isInViewport('#isi')) {
			$('body').addClass('detach-isi');
			$('.trigger.opened').removeClass('opened').addClass('closed');
			$('.collapsed-isi .isi-indications-wrapper').scrollTop(0);
			$('.collapsed-isi').removeClass('tall');
		} else {
			$('body').removeClass('detach-isi');
		}
	},

	isInViewport: function isInViewport(element) {
		var collapsedIsiHeight = $('.collapsed-isi').outerHeight();
		var isiPaddingTop = $('#isi').css('padding-top');
		isiPaddingTop = parseInt(isiPaddingTop.replace('px', ''));

		var elementTop = $(element).offset().top;
		var elementBottom = elementTop + $(element).outerHeight();

		var viewportTop = $(window).scrollTop();
		var viewportBottom = viewportTop + $(window).height() - collapsedIsiHeight - isiPaddingTop;

		return elementTop < viewportBottom;
	}

};

},{"./scrollTo.js":4,"./ui.js":5,"lodash":"lodash"}],2:[function(require,module,exports){
'use strict';

var ui = require('./ui.js');
var scrollTo = require('./scrollTo.js');
var isi = require('./isi.js');
var nav = require('./nav.js');

$(document).ready(function () {
	ui.init();
	scrollTo.init();
	isi.init();
	// nav.init();
});

},{"./isi.js":1,"./nav.js":3,"./scrollTo.js":4,"./ui.js":5}],3:[function(require,module,exports){
'use strict';

var ui = require('./ui.js');
var scrollTo = require('./scrollTo.js');
var stickybits = require('stickybits');

var NavService = {

	init: function init() {
		stickybits('.header-links');
	}
};

module.exports = NavService;
window.NavService = NavService;

},{"./scrollTo.js":4,"./ui.js":5,"stickybits":"stickybits"}],4:[function(require,module,exports){
'use strict';

var ui = require('./ui.js');

var ScrollService = {
	/**
  * Setup a listener for a hashchange and also see if there was a hash defined on pageload
  * @author Rob Szpila <robert@arteric.com>
  */
	init: function init() {

		// Excerpt from jQuery Easing v1.3 - http://gsgd.co.uk/sandbox/jquery/easing/
		// jQuery.easing['swing'] = jQuery.easing['swing'];
		jQuery.extend(jQuery.easing, {
			easeInOutCubic: function easeInOutCubic(x, t, b, c, d) {
				if ((t /= d / 2) < 1) return c / 2 * t * t * t + b;
				return c / 2 * ((t -= 2) * t * t + 2) + b;
			}
		});

		var self = this;
		var hash = location.hash;
		var hashOBJ = hash;
		var ieOffsetDone = false;

		if (hash.length) {
			setTimeout(function () {
				self.scrollTo(hash, false);
			}, 100);
		}

		$('body').on('click', 'a[href*="#"]:not(#roadblock-cancel-button)', function (e) {
			var $this = $(this);
			var href = $this.attr('href');
			var hash = href.substring(href.indexOf('#'), href.length);

			if ($(this).attr('id') != 'roadblock-cancel-button') {
				//ui.unFreezeScroll();
				if (self.hashInDOM(hash)) {
					e.preventDefault();
					self.scrollToHashHelper(hash);
				}
			}
		});
	},

	/**
  * A wrapper function that first checks for a modal, then calls scrollToHash
  * just an attempt to keep things a bit more DRY
  * @param  {jQuery} $hashElement the DOM element to jump to
  * @author Rob Szpila <robert@arteric.com>
  */
	scrollToHashHelper: function scrollToHashHelper(hash) {
		//console.log('scrollToHashHelper = '+hash);
		var self = this;
		self.scrollToHash(hash);
	},

	/**
  * Given an element (in this case a jumplink target) scroll to it and dont ask any qestions
  * @param  {jQuery} $hashElement the DOM element to jump to
  * @author Rob Szpila <robert@arteric.com>
  */
	scrollToHash: function scrollToHash(hash) {
		//console.log('scrollToHash = '+$(hash).offset().top);

		// if ie11, force 1px movement to fix scrolling bug
		//IE11 detection
		var ua = window.navigator.userAgent;
		var trident = ua.indexOf('Trident/');
		var ieOffset = 0;
		var scroll = $(window).scrollTop();

		if (trident > 0) {

			if (scroll <= 10) {
				ieOffset = 125;
			} else {
				ieOffset = 0;
			}

			//console.log('ie11 tweak '+this.ieOffsetDone);
		}

		var headerOffset = $('header').outerHeight();
		var offset = $(hash).offset().top - headerOffset;
		// Calculate the distance and base the duration off of that (but cap it at 1 second for now)

		var distance = Math.abs(offset - $(window).scrollTop());
		// Arbitary values that feel right, 200ms per 500px of page to scroll

		var speed = 250 * (distance / 500);
		if (speed > 2750) {
			speed = 2750;
		}

		//console.log('scrollToHash = '+$(hash).offset().top);

		//console.log('scrollToHash speed = '+speed);

		$('html, body').animate({
			'scrollTop': offset
		}, {
			'duration': speed,
			'easing': 'easeInOutCubic'
		});
	},

	// a generic version of Rob's scroll mechanism where we can scroll to anything passed in, with an exposed callback
	// Jeremy McAllister, 5 Feb 2019
	scrollTo: function scrollTo(selector, callback) {
		//console.log('scrollTO '+selector+$(selector).offset().top);

		var $dest = selector instanceof jQuery ? selector : $(selector);
		if ($dest.length == 0) {
			return false;
		}

		var headerOffset = $('header').outerHeight();
		var offset = $(selector).offset().top - headerOffset;

		// Calculate the distance and base the duration off of that (but cap it at 1 second for now)
		var distance = Math.abs(offset - $(window).scrollTop());

		var speed = 550 * (distance / 500);
		if (speed > 2750) {
			speed = 2750;
		}

		$('html, body').animate({
			'scrollTop': offset
		}, {
			'duration': speed,
			'easing': 'easeInOutCubic'
		});
	},

	/**
  * Check the DOM for a hash, if there isnt one, it's probably a modal or something
  * @param  {string}		hash the hash from the URL
  * @return {bool}		if there hash exists in the DOM or not
  */
	hashInDOM: function hashInDOM(hash) {
		var haveHash = false;
		this.$hashElement = $(hash);
		if (this.$hashElement.length > 0) {
			haveHash = true;
		}
		return haveHash;
	}
};

module.exports = ScrollService;

// expose this ScrollService to the window for use in markup files
window.ScrollService = ScrollService;

},{"./ui.js":5}],5:[function(require,module,exports){
'use strict';

var _ = require('lodash');

module.exports = {
	/**
  * Use this for global UI events, calulations etc
  */
	init: function init() {
		var _this = this;

		// Set up some variables
		this.$window = $(window);
		this.$body = $('body');
		this.mobileMediaQuery = "( max-width: 767px )";
		this.tabletMediaQuery = "( max-width: 1023px )";
		this.isMobile = false;
		this.isTablet = false;
		this.tabletClass = 'isTablet';
		this.mobileClass = 'isMobile';
		this.touchInputClass = 'touchInput';
		this.freeze = false;
		this.scrollTop = 0;

		// Get the display size
		this.displaySize();
		// Detect touch y'all
		this.detectTouch();

		/** A debounced resize function */
		var debouncedResize = _.throttle(function () {
			_this.resize();
		}, 50);
		// call the debounced function on resize
		this.$window.resize(function () {
			debouncedResize();
		});

		/** A debounced scroll function */
		var debouncedScroll = _.throttle(function () {
			_this.scroll();
		}, 50);
		// call the debounced function on resize
		this.$window.scroll(function () {
			debouncedScroll();
		});
	},

	/**
  * Resize triggers go here, this should be debounced in init
  */
	resize: function resize() {
		// Detect if we've hit a display threshold (to/from destop/mobile) and detect the actual size
		this.displayDetection();
	},

	/**
  * Scroll triggers go here, this should be debounced in init
  */
	scroll: function scroll() {
		// Probably ISI and sticky header stuff, once we make those
		var isiActive = this.isAtTop('.header-links');

		// console.log(isiActive);

		if (isiActive == true) {
			$('.header-links').addClass('sticky');
		} else {
			$('.header-links').removeClass('sticky');
		}
	},

	/**
  * Detects a touch event and add a class to the body, used in conjunction with screen size
  * detection, we should be able to infer mobile devices
  * @author Rob Szpila <robert@arteric.com>
  */
	detectTouch: function detectTouch() {
		var _this2 = this;

		/** A touch event listener that removes itself */
		$(document).one('touchstart', function () {
			_this2.$body.addClass(_this2.touchInputClass);
		});
	},

	isAtTop: function isAtTop(element) {
		// console.log('isattop');
		var elementTop = $(element).offset().top;
		var viewportTop = $(window).scrollTop();

		// console.log(elementTop +' '+viewportTop);

		return elementTop <= viewportTop;
	},

	/**
  * Using handy matchMeda we can be super accurate in JS about media queries...horray for parity
  * called from displayDetection on resize
  * @author Rob Szpila <robert@arteric.com>
  */
	displaySize: function displaySize() {
		if (window.matchMedia(this.tabletMediaQuery).matches) {
			// We're totally not mobile...probably
			this.isMobile = false;
			this.$body.removeClass(this.mobileClass);
			// We're TABLET!
			this.isTablet = true;
			this.$body.addClass(this.tabletClass);
		}
		if (window.matchMedia(this.mobileMediaQuery).matches) {
			// We're totally not tablet...definitely
			this.isTablet = false;
			this.$body.removeClass(this.tabletClass);
			// We're mobile!
			this.isMobile = true;
			this.$body.addClass(this.mobileClass);
		}
		if (!window.matchMedia(this.mobileMediaQuery).matches && !window.matchMedia(this.tabletMediaQuery).matches) {
			// We're nothing
			this.isTablet = false;
			this.isMobile = false;
			this.$body.removeClass(this.mobileClass).removeClass(this.tabletClass);
		}
	},

	/**
  * Emit a thing when we cross display thresholds
  */
	displayDetection: function displayDetection() {
		// Before we determine our size now, were we a larger size before?
		var wasLargeView = !this.isTablet && !this.isMobile;

		// Get the display size
		this.displaySize();

		// If we just changed down to tablet or mobile, lets get the user to where they were
		if (wasLargeView && (this.isTablet || this.isMobile)) {
			// Let's emit an event, because this is handy
			$(document).trigger('sizedToMobile');
		}
		// If we just changed up, emit an event
		if (!wasLargeView && !this.isTablet && !this.isMobile) {
			$(document).trigger('sizedToDesktop');
		}
	},

	/**
  * Check if we're at tablet or lower and set the body to fixed and preserve scroll
  * @author Rob Szpila <robert@arteric.com>
  */
	freezeScroll: function freezeScroll() {
		this.scrollTop = $(window).scrollTop();
		this.$body.addClass('fixed');
		this.$body.css('top', this.scrollTop * -1);
	},

	/**
  * Check if the scroll is frozen and unfreeze
  * @author Rob Szpila <robert@arteric.com>
  */
	unFreezeScroll: function unFreezeScroll() {
		if (this.$body.hasClass('fixed')) {
			this.$body.removeClass('fixed');
			this.$body.css('top', 'auto');
			$(window).scrollTop(this.scrollTop);
		}
	}
};

},{"lodash":"lodash"}]},{},[2]);
